import React from "react";
import Countdown from "react-countdown";
import teamPic from "../../assets/images/portraitape.png";
import ethLogo from "../../assets/images/eth.png";
import FAQ from "../FAQ";
import reactStringReplace from "react-string-replace";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import classNames from "classnames";
import { isIOS, isMobile } from "../../support/utils";
import styles from "./Home.module.scss";
import { useAtom } from "jotai";
import icon1 from "../../assets/images/Chibi 2.png";
import icon2 from "../../assets/images/Chibi 3.png";
import icon3 from "../../assets/images/Chibi 4.png";
import icon4 from "../../assets/images/Chibi 5.png";
import {
  accountAtom,
  buyDialogAtom,
  contractAtom,
  metamaskAtom,
  priceInfoAtom,
  siteDataAtom,
} from "../../support/atoms";
import api from "../../support/api";
import Button from "@material-ui/core/Button";

const icons = [
  { src: icon1, width: "30" },
  { src: icon2, width: "30" },
  { src: icon3, width: "30" },
  { src: icon4, width: "37" },
  { src: icon4, width: "30" },
];

const Home = () => {
  const [enabled] = useAtom(metamaskAtom);
  const [account] = useAtom(accountAtom);
  const [contractInfo, setContractInfo] = useAtom(contractAtom);
  const [, setBuyDialog] = useAtom(buyDialogAtom);
  const [siteData] = useAtom(siteDataAtom);
  const [priceInfo] = useAtom(priceInfoAtom);

  const actionButtonText = () => {
    if (contractInfo.state === "created" && contractInfo.saleStart) {
      return (
        <React.Fragment>
          <Countdown
            date={parseInt(contractInfo.saleStart) * 1000 + 10000}
            renderer={({ days, hours, minutes, seconds, completed }) => {
              if (completed) {
                enabled > 0
                  ? api.info().then((data) => setContractInfo(data))
                  : setContractInfo((prev) => ({ ...prev, state: "started" }));
              }

              return (
                <span>
                  {siteData.home.firstSection.actionButtonNotStarted} {days}D{" "}
                  {hours}h {minutes}m {seconds}s
                </span>
              );
            }}
          />
        </React.Fragment>
      );
    } else if (
      contractInfo.state === "started" ||
      contractInfo.state === "revealed"
    )
      return enabled > 0 ? (
        siteData.home.firstSection.actionButton.replace(
          "{{currentPrice}}",
          priceInfo.eth || 0
        )
      ) : (
        <div
          onClick={async () =>
            window.open(
              isMobile()
                ? isIOS()
                  ? siteData.common.iosMetaMask
                  : siteData.common.androidMetaMask
                : siteData.common.chromeMetaMask,
              "_blank"
            )
          }
        >
          {siteData.home.firstSection.actionButtonDisabled}
        </div>
      );
    else if (["ended", "revealed/ended"].includes(contractInfo.state))
      return siteData.home.firstSection.actionButtonEnded;
    else return siteData.home.firstSection.actionButtonNotCreated;
  };

  const progressText = () => {
    if (contractInfo.state === "created")
      return reactStringReplace(
        siteData.home.progressSection.headerNotStarted,
        "{{supply}}",
        (match, i) => (
          <span className="af-class-text-span" key={match + i}>
            {contractInfo && (contractInfo.supply ?? siteData.cards.supply)}
          </span>
        )
      );
    else if (
      contractInfo.state === "started" ||
      contractInfo.state === "revealed"
    )
      return reactStringReplace(
        siteData.home.progressSection.header,
        "{{percentageSold}}",
        (match, i) => (
          <span className="af-class-text-span" key={match + i}>
            {(
              contractInfo &&
              (contractInfo.currentSupply / contractInfo.supply) * 100
            ).toFixed(1)}
            %
          </span>
        )
      );
    else if (["ended", "revealed/ended"].includes(contractInfo.state))
      return reactStringReplace(
        siteData.home.progressSection.headerEnded,
        /({{.+}})/g,
        (match, i) => (
          <span key={i + match} className="af-class-text-span">
            {(
              siteData.home.progressSection.headerEnded.match(/{{.+}}/)[0] || ""
            )
              .replace("{{", "")
              .replace("}}", "")}
          </span>
        )
      );
  };

  return (
    <span>
      <span className="af-view">
        <div className="af-class-body-2">
          <div className="af-class-over-copy">
            <div className="af-class-div-block">
              <h1 className="af-class-heading">
                {siteData.home.firstSection.header}
              </h1>
              <p className="af-class-paragraph-copy-copy">
                {siteData.home.firstSection.paragraph}
              </p>
              <Button
                color="primary"
                variant="contained"
                className={styles.actionButton}
                onClick={async () =>
                  account &&
                  enabled > 0 &&
                  ["started", "revealed"].includes(contractInfo.state)
                    ? setBuyDialog(true)
                    : !account && (await api.connectWallet())
                }
              >
                {actionButtonText()}
              </Button>
            </div>
          </div>
          <div className="af-class-div-x">
            <header id="hero" className="af-class-section-4">
              <div className="af-class-flex-container w-container">
                <div className="af-class-div-block">
                  <h1 className="af-class-heading">
                    {siteData.home.firstSection.header}
                  </h1>
                  <p className="af-class-paragraph-copy-copy">
                    {siteData.home.firstSection.paragraph}
                  </p>
                  <Button
                    color="primary"
                    variant="contained"
                    className={styles.actionButtonFull}
                    onClick={async () =>
                      account &&
                      enabled > 0 &&
                      ["started", "revealed"].includes(contractInfo.state)
                        ? setBuyDialog(true)
                        : !account && (await api.connectWallet())
                    }
                  >
                    {actionButtonText()}
                  </Button>
                </div>
                <div className="af-class-hero-image-mask">
                  <video
                    playsInline
                    autoPlay
                    loop
                    muted
                    className={styles.video}
                  >
                    <source src="Pack.mkv" type="video/mp4" />
                  </video>
                </div>
              </div>
            </header>
          </div>
          <div className="af-class-over">
            <h1 className="af-class-heading-x">{progressText()}</h1>
            <div className={classNames(styles.progressContainer)}>
              <div className={classNames(styles.progressPrices)}>
                {siteData.cards.prices.map((step, i) => {
                  const percent =
                    (step.lastToken / siteData.cards.supply) * 100;
                  const lastPercent = siteData.cards.prices[i - 1]
                    ? (siteData.cards.prices[i - 1].lastToken /
                        siteData.cards.supply) *
                      100
                    : 0;
                  const positionPercent =
                    percent - 2 - (percent - lastPercent) / 2;
                  return (
                    <div
                      key={step.eth + i}
                      className={styles.progressText}
                      style={{
                        left: `${positionPercent}%`,
                      }}
                    >
                      {step.eth} <img alt="eth" src={ethLogo} height={17} />
                    </div>
                  );
                })}
              </div>
              <ProgressBar
                percent={
                  contractInfo &&
                  (contractInfo.currentSupply / siteData.cards.supply) * 100
                }
                filledBackground="linear-gradient(to right, #541a52, #e443de)"
                unfilledBackground="linear-gradient(to right, #ccc, #fff)"
                hasStepZero={false}
                stepPositions={siteData.cards.prices
                  .map((step) => (step.lastToken / siteData.cards.supply) * 100)
                  .slice(0, -1)}
              >
                {siteData.cards.prices.length > 1
                  ? siteData.cards.prices
                      .map((step, i) => (
                        <Step transition="scale" key={i + step.lastToken}>
                          {({ accomplished }) => (
                            <img
                              alt="milestone"
                              style={{
                                filter: `grayscale(${accomplished ? 0 : 100}%)`,
                              }}
                              width="30"
                              src={step.icon}
                            />
                          )}
                        </Step>
                      ))
                      .slice(0, -1)
                  : icons.map((item) => (
                      <Step transition="scale">
                        {({ accomplished }) => (
                          <img
                            alt="milestone"
                            style={{
                              filter: `grayscale(${accomplished ? 0 : 100}%)`,
                            }}
                            width={item.width}
                            src={item.src}
                          />
                        )}
                      </Step>
                    ))}
              </ProgressBar>
              <div className={classNames(styles.progressNfts)}>
                {siteData.cards.prices.map((step, i) => {
                  const percent =
                    (step.lastToken / siteData.cards.supply) * 100;
                  const lastPercent = siteData.cards.prices[i - 1]
                    ? (siteData.cards.prices[i - 1].lastToken /
                        siteData.cards.supply) *
                      100
                    : 0;
                  const prevLastToken = siteData.cards.prices[i - 1]
                    ? siteData.cards.prices[i - 1].lastToken
                    : 0;
                  const positionPercent =
                    percent - 2 - (percent - lastPercent) / 2;
                  return (
                    <div
                      key={step.lastToken + i}
                      className={styles.progressText}
                      style={{
                        left: `${positionPercent}%`,
                      }}
                    >
                      {step.lastToken - prevLastToken}
                    </div>
                  );
                })}
              </div>
            </div>
            <h1 className="af-class-heading-4">
              {siteData.home.selectCards.header}
            </h1>
          </div>
          <div className="af-class-div-block-3">
            <div id="hero2" className="af-class-section-3">
              <div className="af-class-flex-container w-container">
                <div className="w-layout-grid af-class-grid-2">
                  {siteData.home.selectCards.images?.map((src, i) => (
                    <img
                      key={src + i}
                      src={src}
                      loading="lazy"
                      sizes="100vw"
                      alt={`card${i}`}
                      className="af-class-image-11"
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="af-class-flex-container-copy w-container">
            <div className="af-class-div-block" />
            <div className="af-class-hero-image-mask">
              <div className="af-class-section-3-copy">
                <h1 className="af-class-heading-4 af-class-mobile">
                  {siteData.home.secondSection.header}
                </h1>
                <p className={classNames("af-class-why", styles.paragraph)}>
                  {siteData.home.secondSection.paragraph}
                </p>
              </div>
              <div className="af-class-section-3">
                <h1 className="af-class-heading-4-copy">
                  {siteData.home.teamSection.header}
                </h1>
                <h1 className="af-class-heading-4-copy-copy">
                  {siteData.home.teamSection.name}
                </h1>
                <img
                  src={teamPic}
                  loading="lazy"
                  width={250}
                  alt="teamCard"
                  className="af-class-image-7"
                />
                <p className={classNames("af-class-team", styles.paragraph)}>
                  {siteData.home.teamSection.paragraph}
                </p>
              </div>
              <h1 className="af-class-heading-5">
                {siteData.home.faqSection.header}
              </h1>
            </div>
          </div>
          <div className="af-class-section-faq">
            <div className="af-class-content-wrapper af-class-slim w-container">
              <div className="af-class-header-center-box af-class-_75" />
              <div className="af-class-accordion-wrapper">
                <FAQ />
              </div>
            </div>
          </div>
        </div>
      </span>
    </span>
  );
};

export default Home;

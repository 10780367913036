import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import styles from "./Wallet.module.scss";
import api from "../../support/api";
import hiddenCard from "../../assets/images/hiddenCard.png";
import { useHistory } from "react-router";
import { useAtom } from "jotai";
import {
  accountAtom,
  balanceAtom,
  buyDialogAtom,
  cardsAtom,
  contractAtom,
  metamaskAtom,
  priceInfoAtom,
  siteDataAtom,
} from "../../support/atoms";
import reactStringReplace from "react-string-replace";
import { isIOS, isMobile } from "../../support/utils";
import CircularProgress from "@material-ui/core/CircularProgress";

const Wallet = () => {
  const history = useHistory();
  const [enabled] = useAtom(metamaskAtom);
  const [contractInfo] = useAtom(contractAtom);
  const [account, setAccount] = useAtom(accountAtom);
  const [balance] = useAtom(balanceAtom);
  const [, setBuyDialog] = useAtom(buyDialogAtom);
  const [cards, setCards] = useAtom(cardsAtom);
  const [siteData] = useAtom(siteDataAtom);
  const [priceInfo] = useAtom(priceInfoAtom);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    const fetchCards = async () => {
      setCards(await api.getAccountCards(account));
      setIsFetching(false);
    };
    if (
      account &&
      ["revealed", "revealed/ended"].includes(contractInfo.state) &&
      !isFetching
    ) {
      setIsFetching(true);
      fetchCards();
    }
    // eslint-disable-next-line
  }, [account, enabled, setCards, contractInfo, balance]);

  const walletHeader = () => {
    if (enabled > 0 && account)
      return reactStringReplace(
        siteData.wallet.header,
        "{{balance}}",
        (match, i) => (
          <span className="af-class-text-span" key={match + i}>
            {balance || 0}
          </span>
        )
      );
    else if (enabled > 0)
      return (
        <Button
          color="primary"
          variant="contained"
          onClick={async () =>
            await api
              .connectWallet()
              .then(async () => setAccount((await api.accounts())[0]))
          }
        >
          {siteData.common.connectWallet}
        </Button>
      );
    else {
      return (
        <Button
          color="primary"
          variant="contained"
          onClick={async () =>
            window.open(
              isMobile()
                ? isIOS()
                  ? siteData.common.iosMetaMask
                  : siteData.common.androidMetaMask
                : siteData.common.chromeMetaMask,
              "_blank"
            )
          }
        >
          {siteData.common.getMetaMask}
        </Button>
      );
    }
  };

  const cardList = (
    cards ||
    Array(balance || 0)
      .fill(null)
      .map((item, i) => ({ id: i, image: hiddenCard }))
  )
    .sort((a, b) => a.id - b.id)
    .map((card) => (
      <Button
        key={card.id}
        variant="text"
        className={styles.button}
        onClick={() =>
          ["revealed", "revealed/ended"].includes(contractInfo.state) &&
          history.push(`/gallery/card/${card.id}`)
        }
      >
        <img
          src={card.image}
          alt="card"
          className={styles.image}
          loading="eager"
        />
      </Button>
    ));

  return (
    <span className="af-view">
      <div className="af-class-body">
        <Container className={styles.container}>
          <h1 className="af-class-heading-x">{walletHeader()}</h1>
          {enabled === 1 &&
            balance === 0 &&
            account &&
            ["started", "revealed"].includes(contractInfo.state) && (
              <Button
                className={styles.actionButton}
                color="primary"
                variant="contained"
                onClick={() => setBuyDialog(true)}
              >
                {siteData.home.firstSection.actionButton.replace(
                  "{{currentPrice}}",
                  priceInfo.eth || 0
                )}
              </Button>
            )}
          {!isFetching || enabled < 1 ? (
            cardList
          ) : (
            <CircularProgress className={styles.loading} />
          )}
        </Container>
      </div>
    </span>
  );
};

export default Wallet;

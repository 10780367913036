import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@material-ui/core/styles";

import Home from "./components/pages/Home";
import Gallery from "./components/pages/Gallery";
import Wallet from "./components/pages/Wallet";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import axios from "axios";
import { contractAtom, siteDataAtom } from "./support/atoms";
import { useAtom } from "jotai";
import localStorageApi from "./support/localStorage";

const theme = responsiveFontSizes(
  createMuiTheme({
    palette: {
      type: "dark",
      info: {
        main: "#e443de",
      },
      primary: {
        main: "#e443de",
        secondary: "#3e4491",
        alternative: "#3a9efd",
        special: "#f7a400",
      },
      text: {
        primary: "#fff",
        secondary: "#ccc",
        disabled: "#3a9efd",
        hint: "#f7a400",
      },
      background: {
        paper: "#000",
        default: "#0d161c",
      },
    },
    typography: {
      fontFamily: ["Sfpixelate", "Pixelated", "Sfpixelateshaded"],
    },
    overrides: {
      MuiDialog: {
        paper: {
          borderWidth: 5,
          borderRadius: 5,
          borderColor: "#fff",
          borderStyle: "ridge",
          backgroundColor: "#101010",
          color: "#fff",
        },
      },
      MuiButton: {
        root: {
          borderRadius: 0,
          fontWeight: 600,
          marginBottom: 30,
          // marginLeft: "auto",
          // marginRight: "auto",
          "&:hover": {
            backgroundColor: "rgb(159 46 155)",
          },
        },
      },
      MuiButtonBase: {
        root: { margin: 10 },
      },
      MuiInputBase: {
        input: { textAlign: "center" },
      },
    },
  })
);

const App = () => {
  const [siteData, setSiteData] = useAtom(siteDataAtom);
  const [contractInfo, setContractInfo] = useAtom(contractAtom);

  useEffect(() => {
    getSiteData();
    //eslint-disable-next-line
  }, []);

  const getSiteData = async () => {
    axios
      .get("/siteData.json")
      .then((response) => {
        localStorageApi.setSiteData(response.data);

        let state;
        if (
          parseInt(response.data.cards.startingIndex) &&
          response.data.cards.currentSupply === response.data.cards.supply
        )
          state = "revealed/ended";
        else if (parseInt(response.data.cards.startingIndex))
          state = "revealed";
        else if (
          response.data.cards.currentSupply === response.data.cards.supply
        )
          state = "ended";
        else if (parseInt(response.data.cards.saleStart) * 1000 < Date.now())
          state = "started";
        else state = "created";
        setSiteData(response.data);
        setContractInfo({
          startingIndex: response.data.cards.startingIndex,
          supply: response.data.cards.supply,
          currentSupply: response.data.cards.currentSupply,
          saleStart: response.data.cards.saleStart,
          state,
        });
      })
      .catch((err) => console.log(err));
  };

  return (
    <Router>
      {contractInfo && siteData && (
        <ThemeProvider theme={theme}>
          <Navigation />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route
              exact
              path={["/gallery", "/gallery/card/:id"]}
              component={Gallery}
            />
            <Route path="/app" component={Wallet} />
            <Redirect to="/" />
          </Switch>
          <Footer />
        </ThemeProvider>
      )}
    </Router>
  );
};

export default App;

import "./theme/theme.scss";
import "./assets/css/custom.css";
import "./assets/css/normalize.css";
import "./assets/css/webflow.css";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

ReactDOM.render(<App />, document.getElementById("root"));

function isIOS() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}
const isMobile = () =>
  navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);

export { isIOS, isMobile };

import { useAtom } from "jotai";
import React from "react";
import styles from "./Footer.module.scss";
import { disclaimerAtom, termsServiceAtom } from "../support/atoms";
import classNames from "classnames";

const Footer = () => {
  const [, setTermsService] = useAtom(termsServiceAtom);
  const [, setDisclaimer] = useAtom(disclaimerAtom);

  return (
    <span className="af-view">
      <footer id="footer" className="af-class-footer">
        <div className="w-container">
          <div className="af-class-footerr">
            Made With <span>🤍</span> by DeFi Labs Inc. © 2021 |&nbsp;&nbsp;
            <span>
              <span
                className={classNames("af-class-text-span-2", styles.link)}
                onClick={() => setDisclaimer(true)}
              >
                Disclaimer
              </span>
            </span>{" "}
            &amp;{" "}
            <span>
              <span
                className={classNames("af-class-text-span-3", styles.link)}
                onClick={() => setTermsService(true)}
              >
                Terms of Services
              </span>
            </span>
          </div>
        </div>
      </footer>
      {/* [if lte IE 9]><![endif] */}
    </span>
  );
};

export default Footer;

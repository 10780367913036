import React, { useState } from "react";
import styles from "./FAQ.module.scss";
import classNames from "classnames";
import { useAtom } from "jotai";
import { siteDataAtom } from "../support/atoms";

const FAQ = () => {
  const [siteData] = useAtom(siteDataAtom);
  const [tabs, setTabs] = useState({});

  return siteData.home.faqSection.faq?.map((item, i) => {
    return (
      <div key={item.question} className="af-class-accordion-item">
        <div
          className="af-class-accordion-item-trigger"
          onClick={() =>
            setTabs((prevTabs) => ({ ...prevTabs, [i]: !prevTabs[i] }))
          }
        >
          <h1 className="af-class-heading-6">
            <strong className="af-class-pressed">{item.question}</strong>
          </h1>
          <div className="af-class-icon-2 af-class-accordion-icon">
            <div
              className={classNames(
                tabs[i] ? styles.iconOpen : styles.iconClosed
              )}
            >
              <em className="af-class-italic-text-5"></em>
            </div>
          </div>
        </div>
        <div
          className={classNames(tabs[i] ? styles.tabOpen : styles.tabClosed)}
        >
          <div className="af-class-accordion-item-content">
            <p className="af-class-paragraph-5">{item.answer}</p>
          </div>
        </div>
      </div>
    );
  });
};

export default FAQ;

import React, { useEffect, useState } from "react";
import classNames from "classnames";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Link, useHistory, useLocation } from "react-router-dom";
import api from "../support/api";
import { useAtom } from "jotai";
import {
  metamaskAtom,
  accountAtom,
  balanceAtom,
  contractAtom,
  transactionsAtom,
  buyDialogAtom,
  termsServiceAtom,
  disclaimerAtom,
  priceInfoAtom,
  siteDataAtom,
} from "../support/atoms.js";
import { isIOS, isMobile } from "../support/utils";
import styles from "./Navigation.module.scss";
import logo from "../assets/images/logo.png";

const Navigation = () => {
  const history = useHistory();
  const location = useLocation();
  const [enabled, setEnabled] = useAtom(metamaskAtom);
  const [balance, setBalance] = useAtom(balanceAtom);
  const [account, setAccount] = useAtom(accountAtom);
  const [priceInfo, setPriceInfo] = useAtom(priceInfoAtom);
  const [, setContractInfo] = useAtom(contractAtom);
  const [, setTransactions] = useAtom(transactionsAtom);
  const [buyDialog, setBuyDialog] = useAtom(buyDialogAtom);
  const [termsService, setTermsService] = useAtom(termsServiceAtom);
  const [disclaimer, setDisclaimer] = useAtom(disclaimerAtom);
  const [siteData] = useAtom(siteDataAtom);

  const [alertBar, setAlertBar] = useState(false);
  const [error, setError] = useState();
  const [loaded, setLoaded] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState(1);
  const [accepted, setAccepted] = useState(false);
  const [contractInfo] = useAtom(contractAtom);

  const [attributes, setAttributes] = useState({
    "data-nav-menu-open": undefined,
  });

  const handleBuy = async (numberOfNfts) => {
    await api.buy(
      numberOfNfts,
      priceInfo,
      account,
      async (tx) => setBuyDialog(false),
      (receipt) => {}, //setBalance((prev) => parseInt(prev) + numberOfNfts),
      (err, receipt) => setError(err.message.substring(0, 80))
    );
  };

  const updatePriceInfo = async () => {
    setPriceInfo(await api.getPriceInfo());
  };

  useEffect(() => {
    const fetchData = async () => {
      setEnabled(await api.checkMetaMask());
      api.accounts().then((accounts) => {
        setAccount(accounts[0]);
      });
    };
    fetchData();
  }, [setEnabled, setAccount]);

  useEffect(() => {
    const setup = async () => {
      const account = (await api.accounts())[0];
      const chainId = await window.web3.eth.getChainId();
      setPriceInfo(await api.getPriceInfo().catch(() => ({})));
      if (chainId !== 1) setAlertBar(true);

      api.onAccountChange((accounts) => {
        setAccount(window.web3.utils.toChecksumAddress(accounts[0]));
      });
      api.onTransfer(async (err, data) => {
        if (!parseInt(data.from)) {
          if (data.to === account) setBalance((prev) => prev + 1);

          setPriceInfo((prev) => {
            if (parseInt(data.tokenId) + 1 >= prev.lastToken) updatePriceInfo();

            return prev;
          });

          setContractInfo((prev) => {
            if (parseInt(data.tokenId) + 1 > prev.currentSupply) {
              return {
                ...prev,
                state:
                  parseInt(data.tokenId) + 1 === parseInt(contractInfo.supply)
                    ? "ended"
                    : prev.state,
                currentSupply: parseInt(data.tokenId) + 1,
              };
            } else return prev;
          });
          if (parseInt(data.tokenId) + 1 === parseInt(contractInfo.supply))
            setBuyDialog(false);
        }
      });
      api.onChainChange(async (id) => {
        if (id !== 1) {
          setAlertBar(true);
        } else {
          setAlertBar(false);
          setContractInfo(await api.info());
        }
      });
    };

    if (!loaded && enabled > 0) {
      setLoaded(true);
      setup();
    }

    return () => {};
    // eslint-disable-next-line
  }, [enabled, account]);

  useEffect(() => {
    const fetchContractInfo = async () => {
      setContractInfo(await api.info());
    };
    const fetchAccountInfo = async () => {
      const accountInfo = await api.getAccountInfo(account);
      setBalance(parseInt(accountInfo.balance));
      setTransactions(accountInfo.txs);
    };
    if (account) fetchAccountInfo();
    if (enabled > 0) fetchContractInfo();
    // eslint-disable-next-line
  }, [enabled, account]);

  const walletButton = () => {
    if (enabled > 0 && account) {
      return (
        <Button
          color="primary"
          variant="contained"
          className={styles.button}
          onClick={() => history.push("/app")} //{async () => await api.test(account)}
        >
          {`${account.substring(0, 6)}... Balance: ${balance}`}
        </Button>
      );
    } else if (!account && enabled > 0) {
      return (
        <Button
          color="primary"
          variant="contained"
          className={styles.button}
          onClick={async () => await api.connectWallet()}
        >
          {siteData.common.connectWallet}
        </Button>
      );
    } else {
      return (
        <Button
          color="primary"
          variant="contained"
          className={styles.button}
          onClick={async () =>
            window.open(
              isMobile()
                ? isIOS()
                  ? siteData.common.iosMetaMask
                  : siteData.common.androidMetaMask
                : siteData.common.chromeMetaMask,
              "_blank"
            )
          }
        >
          {siteData.common.getMetaMask}
        </Button>
      );
    }
  };

  return (
    <span className="af-view">
      <Dialog open={buyDialog} fullWidth={true}>
        <DialogTitle id="alert-dialog-title">Select Amount</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="body1">{`${selectedAmount} Rare Ape(s) at ${
              priceInfo.eth
            } ETH = ${(selectedAmount * parseFloat(priceInfo.eth)).toFixed(
              1
            )} ETH
          `}</Typography>
            <Slider
              value={selectedAmount}
              style={{ paddingTop: 50 }}
              onChange={(event, amount) => setSelectedAmount(amount)}
              valueLabelDisplay="auto"
              step={1}
              marks
              min={1}
              max={
                contractInfo.supply - contractInfo.currentSupply <= 20
                  ? contractInfo.supply - contractInfo.currentSupply
                  : 20
              }
            />
            <FormControlLabel
              onClick={() => setAccepted((prev) => !prev)}
              control={
                <Checkbox size="small" color="primary" checked={accepted} />
              }
              label={
                <Typography variant="caption">
                  Accept{" "}
                  <span
                    onClick={() => setTermsService(true)}
                    className={styles.link}
                  >
                    Terms of Services
                  </span>{" "}
                  &{" "}
                  <span
                    onClick={() => setDisclaimer(true)}
                    className={styles.link}
                  >
                    Disclaimer
                  </span>
                </Typography>
              }
            />
            {error && (
              <Alert variant="outlined" severity="error">
                {`Transaction failed: ${error}`}
              </Alert>
            )}
            {priceInfo.lastToken - contractInfo.currentSupply < 50 &&
              priceInfo.lastToken - contractInfo.currentSupply >= 0 && (
                <Alert variant="outlined" severity="warning">
                  {`${
                    priceInfo.lastToken - contractInfo.currentSupply
                  } Rare Ape(s) remaining ${
                    priceInfo.next
                      ? `until the price increases to ${priceInfo.next.eth} ETH`
                      : ""
                  }`}
                </Alert>
              )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setError(undefined);
              setBuyDialog(false);
            }}
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setError(undefined);
              handleBuy(selectedAmount);
            }}
            disabled={!accepted}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={termsService} fullWidth={true} maxWidth="lg">
        <DialogTitle id="alert-dialog-title">Terms Of Service</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="body1" className={styles.paragraph}>
              {siteData.legal.termsOfService}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setTermsService(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={disclaimer} fullWidth={true} maxWidth="lg">
        <DialogTitle id="alert-dialog-title">Disclaimer</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="body1" className={styles.paragraph}>
              {siteData.legal.disclaimer}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setDisclaimer(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <div className="af-class-body-2">
        <div className="af-class-div-block-4">
          <div
            data-collapse="medium"
            data-animation="default"
            data-duration={400}
            role="banner"
            className="af-class-navbar w-nav"
          >
            <Link to="/" className="w-nav-brand">
              <img src={logo} width={250} alt="logo" className={styles.logo} />
            </Link>

            <nav
              role="navigation"
              className={classNames("af-class-nav-menu", "w-nav-menu")}
              {...attributes}
              onClick={() =>
                setAttributes((prev) => ({
                  "data-nav-menu-open": undefined,
                }))
              }
            >
              <Link
                to="/"
                className={classNames(
                  "af-class-nav-link",
                  "w-nav-link",
                  location.pathname === "/" && "w--current"
                )}
              >
                Home
              </Link>
              <Link
                to="/gallery"
                className={classNames(
                  "af-class-nav-link",
                  "w-nav-link",
                  location.pathname.includes("/gallery/card/") && "w--current"
                )}
              >
                Gallery
              </Link>
              <Link
                to="/app"
                className={classNames(
                  "af-class-nav-link",
                  "w-nav-link",
                  location.pathname === "/app" && "w--current"
                )}
              >
                Wallet
              </Link>
              <a
                href={
                  siteData.navigation.opensea
                    ? siteData.navigation.opensea
                    : "#"
                }
                rel="noreferrer"
                target={siteData.navigation.opensea && "_blank"}
                className="af-class-nav-link w-nav-link"
              >
                OpenSea
              </a>
              <a
                rel="noreferrer"
                href={`https://etherscan.io/address/${siteData.cards.contract}`}
                target="_blank"
                className="af-class-nav-link w-nav-link"
              >
                Contract
                <br />‍
              </a>
              {walletButton()}
            </nav>
            <div
              className="af-class-menu-button w-nav-button"
              onClick={() =>
                setAttributes((prev) => ({
                  "data-nav-menu-open": prev["data-nav-menu-open"]
                    ? undefined
                    : true,
                }))
              }
            >
              <div className="af-class-icon-4 w-icon-nav-menu" />
            </div>
          </div>
        </div>
      </div>
      {alertBar && (
        <Alert
          onClose={() => setAlertBar(false)}
          variant="outlined"
          severity="warning"
          style={{ maxWidth: 1050, margin: "auto", marginTop: 10 }}
        >
          {siteData.common.networkError}
        </Alert>
      )}
    </span>
  );
};

export default Navigation;

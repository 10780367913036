import React, { useEffect, useState } from "react";
import styles from "./Gallery.module.scss";
import api from "../../support/api";
import { Redirect, useHistory, useRouteMatch } from "react-router";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useAtom } from "jotai";
import { contractAtom, metamaskAtom, siteDataAtom } from "../../support/atoms";
import reactStringReplace from "react-string-replace";
import { Box } from "@material-ui/core";

const Gallery = () => {
  const [enabled] = useAtom(metamaskAtom);
  const [contractInfo] = useAtom(contractAtom);
  const [siteData] = useAtom(siteDataAtom);
  const history = useHistory();
  const match = useRouteMatch();
  const [card, setCard] = useState();
  const [id, setId] = useState();
  const [input, setInput] = useState();

  useEffect(() => {
    if (isValid(parseInt(match.params.id))) {
      setId(parseInt(match.params.id));
      setInput(parseInt(match.params.id));
    } else if (parseInt(match.params.id)) history.push("/");
    // eslint-disable-next-line
  }, [match]);

  useEffect(() => {
    const fetchCard = async (id) => {
      setCard(null);
      setInput(id);
      let tokenId =
        parseInt(id) -
        parseInt(contractInfo.startingIndex || siteData.cards.startingIndex);
      if (tokenId < 0)
        tokenId += parseInt(contractInfo.supply || siteData.cards.supply);
      setCard(await api.getCard(tokenId));
    };
    if (isValid(parseInt(match.params.id)) && enabled >= 0)
      fetchCard(parseInt(match.params.id));
    // eslint-disable-next-line
  }, [id, enabled]);

  const isValid = (id) => id >= 0 && id < siteData.cards.supply;

  const rnd = () => {
    return Math.floor(Math.random() * siteData.cards.supply);
  };

  const propertyList = card?.attributes?.map((atr) => {
    const type = atr.trait_type;
    const value = atr.value;
    const percent = (
      (siteData.cards.rarity[type]?.[value] / siteData.cards.supply) *
      100
    ).toFixed(1);
    return (
      <div className={styles.chip} key={type + value}>
        <span className={styles.property}>{type}</span>
        <br />
        <span className="af-class-text-span-6">
          {value}
          <br />
          {percent}%
          <br />‍
        </span>
        <br />‍<br />
      </div>
    );
  });

  return (
    <span className="af-view">
      {match.params.id === undefined && (
        <Redirect to={`/gallery/card/${rnd()}`} />
      )}
      <div className="af-class-body">
        <Grid container className={styles.container}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <h1 className="af-class-heading-x">
              {reactStringReplace(
                siteData.gallery.header,
                /({{.+}})/g,
                (match, i) => (
                  <span key={i + match} className="af-class-text-span">
                    {(siteData.gallery.header.match(/{{.+}}/)[0] || "")
                      .replace("{{", "")
                      .replace("}}", "")}
                  </span>
                )
              )}
            </h1>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            {card ? (
              <div className={styles.loadingContainer}>
                <img src={card.image} alt="card" className={styles.image} />
              </div>
            ) : (
              <CircularProgress className={styles.loading} />
            )}
          </Grid>
          <Box
            component={Grid}
            item
            xs={12}
            className={styles.input}
            display={{ xs: "block", sm: "block", md: "none", lg: "none" }}
          >
            <TextField
              className={styles.input}
              variant="standard"
              color="primary"
              type="number"
              value={input >= 0 && input < siteData.cards.supply ? input : ""}
              onChange={(e) => setInput(parseInt(e.target.value))}
              onKeyDown={(e) =>
                e.key === "Enter" && history.push(e.target.value)
              }
            />
          </Box>
          <Box
            component={Grid}
            item
            xs={12}
            className={styles.buttonContainer}
            display={{ md: "none" }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                isValid(id - 1) && history.push((id - 1).toString())
              }
            >
              {siteData.gallery.previous}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                isValid(id + 1) && history.push((id + 1).toString())
              }
            >
              {siteData.gallery.next}
            </Button>
          </Box>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            className="af-class-heading-4-copy"
          >
            <div className={styles.properties}>
              <h1 className="af-class-heading-4-copy">
                {siteData.gallery.properties}
              </h1>
              {propertyList}

              {card?.notMinted &&
                ["revealed", "revealed/ended"].includes(contractInfo.state) && (
                  <Alert
                    variant="outlined"
                    severity="info"
                    className={styles.alert}
                  >
                    {siteData.gallery.notMinted}
                    {new Date().toLocaleTimeString()}
                  </Alert>
                )}
            </div>
          </Grid>
          <Box
            component={Grid}
            item
            xs={12}
            className={styles.input}
            display={{ xs: "none", md: "block" }}
          >
            <TextField
              className={styles.input}
              variant="standard"
              color="primary"
              type="number"
              value={input >= 0 && input < siteData.cards.supply ? input : ""}
              onChange={(e) => setInput(parseInt(e.target.value))}
              onKeyDown={(e) =>
                e.key === "Enter" && history.push(e.target.value)
              }
            />
          </Box>
          <Box
            component={Grid}
            item
            xs={12}
            className={styles.buttonContainer}
            display={{ xs: "none", md: "block" }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                isValid(id - 1) && history.push((id - 1).toString())
              }
            >
              {siteData.gallery.previous}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                isValid(id + 1) && history.push((id + 1).toString())
              }
            >
              {siteData.gallery.next}
            </Button>
          </Box>
        </Grid>
      </div>
    </span>
  );
};

export default Gallery;

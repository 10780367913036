import { atom } from "jotai";
import localStorageApi from "./localStorage";

export const siteDataAtom = atom();
export const metamaskAtom = atom(-1);
export const accountAtom = atom();
export const transactionsAtom = atom([]);
export const balanceAtom = atom(0);
export const contractAtom = atom();
export const priceInfoAtom = atom({});
export const cardsAtom = atom(localStorageApi.getAccount().cards);
export const buyDialogAtom = atom(false);
export const termsServiceAtom = atom(false);
export const disclaimerAtom = atom(false);

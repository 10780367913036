const localStorage = window.localStorage;

const localStorageApi = {
  getAccount: (address) => {
    return JSON.parse(localStorage.getItem(address)) || {};
  },
  getAccountTx: (address, hash) => {
    const account = JSON.parse(localStorage.getItem(address));
    return account?.transactions
      ? account.transactions.find((tx) => tx.hash === hash)
      : null;
  },
  setAccountTx: (address, tx) => {
    let account = JSON.parse(localStorage.getItem(address));
    let index;
    if (account?.transactions) {
      account.transactions.find((item, i) => {
        if (item.transactionHash === tx.transactionHash) {
          index = i;
          return true;
        }
        return false;
      });
      if (index !== undefined)
        account.transactions[index] = { ...account.transactions[index], ...tx };
      else account.transactions.push(tx);
    } else
      account
        ? (account.transactions = [tx])
        : (account = { transactions: [tx] });

    localStorage.setItem(address, JSON.stringify(account));
  },
  setCard: (card, tokenId) => {
    let cards = JSON.parse(localStorage.getItem("cards"));
    localStorage.setItem(
      "cards",
      JSON.stringify({ ...cards, [tokenId]: card })
    );
  },
  getCard: (tokenId) => JSON.parse(localStorage.getItem("cards"))?.[tokenId],
  setSiteData: (data) => {
    localStorage.setItem("siteData", JSON.stringify(data));
  },
  getSiteData: () => JSON.parse(localStorage.getItem("siteData")),
};

export default localStorageApi;

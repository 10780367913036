import detectEthereumProvider from "@metamask/detect-provider";
import axios from "axios";
import Web3 from "web3";
import abi from "../assets/abi.json";
import localStorageApi from "./localStorage";
import localStorage from "./localStorage";

const siteData = () => localStorageApi.getSiteData();

const NOT_SUPPORTED = "Browser does not support MetaMask Wallet";
const ipfsToHttp = (ipfs) =>
  ipfs.replace("ipfs://", `${siteData().cards.provider}`);

const api = {
  checkMetaMask: async () => {
    return new Promise(async (resolve, reject) => {
      const provider = await detectEthereumProvider({
        mustBeMetaMask: true,
        timeout: 1000,
      });
      if (provider) window.web3 = new Web3(provider);
      else if (!window.web3) {
        resolve(0);
      }
      resolve(1);
    });
  },
  connectWallet: async () => {
    return new Promise(async (resolve, reject) => {
      await window.ethereum
        ?.request({ method: "eth_requestAccounts" })
        .then(resolve)
        .catch(
          (error) =>
            error.code === 4001 && reject("Please connect to MetaMask.")
        );
      reject(NOT_SUPPORTED);
    });
  },
  getCard: async (id) => {
    let calculatedId;

    try {
      let notMinted;
      let tokenURIFallback;
      const contract = new window.web3.eth.Contract(
        abi,
        siteData().cards.contract
      );

      await contract.methods
        .startingIndex()
        .call()
        .then(
          (index) =>
            (calculatedId =
              (parseInt(id) + parseInt(index)) % siteData().cards.supply)
        )
        .catch(
          (err) =>
            (calculatedId =
              (parseInt(id) + siteData().cards.startingIndex) %
              siteData().cards.supply)
        );

      const [tokenURI, owner] = await Promise.all([
        contract.methods.tokenURI(id).call(),
        contract.methods.ownerOf(id).call(),
      ]).catch((err) => {
        notMinted = true;

        tokenURIFallback = `${siteData().cards.provider}${
          siteData().cards.root
        }/${calculatedId}.json`;
        return "Fallback to calculated ID lookup";
      });

      return axios
        .get(tokenURIFallback || ipfsToHttp(tokenURI))
        .then((res) => ({
          ...res.data,
          id: calculatedId,
          image: ipfsToHttp(res.data.image),
          owner: tokenURIFallback ? undefined : owner,
          notMinted,
        }));
    } catch (error) {
      const tokenURIFallback = `${siteData().cards.provider}${
        siteData().cards.root
      }/${id}.json`;
      return axios.get(tokenURIFallback).then((res) => ({
        ...res.data,
        id,
        image: ipfsToHttp(res.data.image),
      }));
    }
  },
  getOwner: async (id) => {
    const contract = new window.web3.eth.Contract(
      abi,
      siteData().cards.contract
    );

    return await contract.methods.ownerOf(id).call();
  },
  accounts: async () => {
    return new Promise(async (resolve, reject) => {
      const accounts = await window.web3?.eth?.getAccounts();
      if (accounts && accounts[0]) resolve(accounts);
      else if (!accounts) reject(NOT_SUPPORTED);
    });
  },
  getAccountInfo: async (address) => {
    const contract = new window.web3.eth.Contract(
      abi,
      siteData().cards.contract
    );
    const balance = await contract.methods.balanceOf(address).call();
    const account = localStorage.getAccount(address);
    return { balance, txs: account?.transactions || [] };
  },
  getAccountTokenIdByIndex: async (address, index) => {
    const contract = new window.web3.eth.Contract(
      abi,
      siteData().cards.contract
    );
    return contract.methods.tokenOfOwnerByIndex(address, index).call();
  },
  getAccountCards: async (address) => {
    const contract = new window.web3.eth.Contract(
      abi,
      siteData().cards.contract
    );
    const balance = await contract.methods.balanceOf(address).call();
    const cards = [];

    await new Promise((resolve) => {
      for (let i = 0; i < balance; i++) {
        api.getAccountTokenIdByIndex(address, i).then(async (id) => {
          const card = localStorageApi.getCard(id) || (await api.getCard(id));
          localStorageApi.setCard(card, id);
          cards.push(card);
          if (i === balance - 1) resolve();
        });
      }
    });
    return cards;
  },
  info: async () => {
    let state;
    const contract = new window.web3.eth.Contract(
      abi,
      siteData().cards.contract
    );
    const [
      name,
      symbol,
      price,
      currentSupply,
      startingIndexBlock,
      startingIndex,
      baseURI,
      saleStart,
      revealStart,
      supply,
    ] = await Promise.all([
      contract.methods.name().call().catch(console.log),
      contract.methods.symbol().call().catch(console.log),
      contract.methods.NFT_PRICE().call().catch(console.log),
      contract.methods.totalSupply().call().catch(console.log),
      contract.methods.startingIndexBlock().call().catch(console.log),
      contract.methods.startingIndex().call().catch(console.log),
      contract.methods.baseURI().call().catch(console.log),
      contract.methods.SALE_START_TIMESTAMP().call().catch(console.log),
      contract.methods.REVEAL_TIMESTAMP().call().catch(console.log),
      contract.methods.MAX_NFT_SUPPLY().call().catch(console.log),
    ]);

    if (parseInt(startingIndex) && supply === currentSupply)
      state = "revealed/ended";
    else if (parseInt(startingIndex)) state = "revealed";
    else if (supply === currentSupply && supply > 0) state = "ended";
    else if (parseInt(saleStart) * 1000 < Date.now()) state = "started";
    else state = "created";

    return {
      name,
      symbol,
      price: window.web3.utils.fromWei(price || "0"),
      currentSupply,
      startingIndexBlock,
      startingIndex,
      baseURI,
      saleStart,
      revealStart,
      supply,
      state,
    };
  },
  getPriceInfo: async () => {
    const contract = new window.web3.eth.Contract(
      abi,
      siteData().cards.contract
    );
    const [price, currentSupply] = await Promise.all([
      contract.methods.NFT_PRICE().call(),
      contract.methods.totalSupply().call(),
    ]);
    const pricePoint = siteData().cards.prices.filter(
      (item) => item.lastToken >= currentSupply
    );
    return {
      eth: window.web3.utils.fromWei(price || "0"),
      wei: price,
      lastToken: pricePoint[0].lastToken,
      remaining: pricePoint[0].lastToken - currentSupply,
      next: pricePoint[1],
    };
  },
  test: async (account) => {
    const contract = new window.web3.eth.Contract(
      abi,
      siteData().cards.contract
    );
    await contract.methods.finalizeStartingIndex().send({ from: account });
  },
  buy: async (numberOfNFTs, price, account, onTX, onReceipt, onError) => {
    const contract = new window.web3.eth.Contract(
      abi,
      siteData().cards.contract
    );

    return new Promise((resolve, reject) => {
      contract.methods
        .mintNFT(numberOfNFTs)
        .send({ from: account, value: (price.wei * numberOfNFTs).toString() })
        .on("transactionHash", (transactionHash) => {
          localStorage.setAccountTx(account, { transactionHash });
          onTX(transactionHash);
        })
        .on("receipt", (receipt) => {
          const { from, to, status, transactionHash } = receipt;
          localStorage.setAccountTx(account, {
            from,
            to,
            status,
            transactionHash,
          });
          onReceipt(receipt);
        })
        .on("error", (error, receipt) => {
          if (receipt) {
            const { from, to, status, transactionHash } = receipt;
            localStorage.setAccountTx(account, {
              from,
              to,
              status,
              transactionHash,
            });
          }

          onError(error, receipt);
        });
    });
  },
  onTransfer: async (onTransfer) => {
    const contract = new window.web3.eth.Contract(
      abi,
      siteData().cards.contract
    );
    contract.events.allEvents((err, data) => {
      if (err) onTransfer(err, data);
      else {
        const {
          returnValues: { from, to, tokenId },
        } = data;
        onTransfer(err, { from, to, tokenId });
      }
    });
  },
  onAccountChange: (callback) =>
    window.ethereum?.on("accountsChanged", callback),
  onDisconnect: (callback) => window.ethereum?.on("disconnect", callback),
  onChainChange: (callback) =>
    window.ethereum?.on("chainChanged", (chainId) =>
      callback(parseInt(chainId))
    ),
};

export default api;
